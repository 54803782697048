.modal{
    width: 100vw;
    height: 100vh;
    padding: 16px;

    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(27, 27, 37, 0.15);

    overflow-y: auto;
}

.modal__frame{
    max-width: 558px;
    width: 100%;
    box-shadow: 0 12px 36px rgba(54, 77, 136, 0.26);
    border-radius: 40px;
}

.modal__section{
    background: #FFFFFF;
    border-radius: 40px;
    position: relative;
    padding: 60px 110px 96px;
}

.modal__cancel{
    background: url("../icons/ic_cancel.svg") no-repeat center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M30.37 30.3715C31.2052 29.5363 31.2052 28.1821 30.37 27.3469L23.022 19.9988L30.3779 12.6429C31.2082 11.8126 31.2082 10.4664 30.3779 9.63604C29.5476 8.80573 28.2014 8.80573 27.3711 9.63604L20.0151 16.992L12.652 9.62886C11.8168 8.79364 10.4626 8.79364 9.62739 9.62886C8.79217 10.4641 8.79217 11.8182 9.62739 12.6535L16.9905 20.0166L9.6353 27.3718C8.80499 28.2022 8.80499 29.5484 9.6353 30.3787C10.4656 31.209 11.8118 31.209 12.6421 30.3787L19.9974 23.0234L27.3454 30.3715C28.1806 31.2067 29.5348 31.2067 30.37 30.3715Z' fill='%23D1D1D6'/%3E%3C/svg%3E");
    width: 40px;
    height: 40px;

    border: 0;
    outline: none;

    position: absolute;
    top: 16px;
    right: 16px;

    cursor: pointer;
}

.modal__title{
    font-weight: 600;
    font-size: 44px;
    line-height: 36px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 40px;
    color: #373A42;
    font-family: 'Barlow Condensed', sans-serif;
}

.modal__note{
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #3C3C43;
    font-family: 'Roboto', sans-serif;
}

.modal__note a{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;

    color: #007AFF;
    display: inline-block;
    margin-left: 5px;
}

.modal__button{
    width: 100%;
    margin: 24px auto 35px;
}

.modal__label{
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    color: #7E7E7E;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin-bottom: 28px;
    font-family: 'Roboto', sans-serif;
}



.modal__p{
    font-size: 14px;
    line-height: 20px;
    color: #7E7E7E;
}

.modal__link{
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;

    color: #007AFF;
    border: 0;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    margin-left: 8px;
}

.modal__timer{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #9F9CB6;
    margin-top: 4px;
}
.modal__description{
    display: flex;
    justify-content: space-between;
    margin: 24px 13px 10px;
    /*min-height: 44px;*/
}
.modal__warning{
    background: #FF3B30 url("../icons/ic_warning.svg") no-repeat left 8px center / 21px;
    border-radius: 8px;
    padding: 11px 8px 9px;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    text-align: center;

    color: #FFFFFF;
    margin-bottom: 16px;
}
.modal__warning_text{
    margin-left: 30px;
}

.modal__code{
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(243, 243, 243);
    border-radius: 7px;
    width: 100%;
    border: none;
    outline: none;
    height: 52px;
    font-size: 20px;
    text-align: center;
    letter-spacing: 28px;
    padding: 0 15px 0 40px;
    font-weight: 900;
    font-family: Roboto;


}

@media screen and (max-width: 1100px){
    .modal{
        align-items: flex-start;
    }

    .modal__section{
        padding: 32px;
        padding-top: 104px;
    }

    .modal__cancel{
        top: 24px;
        right: 24px;
    }
}

/* button START */
.button{
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 8px;
    outline: none;
    color: #007AFF;
    cursor: pointer;
    transition: 200ms;
    background: rgba(0, 122, 255, 0.10);
    font-family: 'Roboto', sans-serif;
}

.button:hover{
    background: #E5EFFA;
}
.button:active{
    background: #B8D4F0;
}

.button_gradient{
    background: linear-gradient(274.9deg, #118CD9 0.18%, #54D2EE 100%);
    box-shadow: 0 20px 46px rgba(76, 202, 236, 0.44);
}

.button_gradient:hover{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(274.9deg, #118CD9 0.18%, #54D2EE 100%);
    box-shadow: 0 20px 46px rgba(76, 202, 236, 0.64);
}

.button_gradient:active{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(274.9deg, #118CD9 0.18%, #54D2EE 100%);
    box-shadow: 0 20px 46px rgba(76, 202, 236, 0.54);;
}

.button__text{
    font-weight: 900;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 1.3px;
    color: #007AFF;
}

.button__text_white{
    color: #FFFFFF
}

.button__icon{
    width: 25px;
    height: 25px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    margin-right: 10px;
    margin-top: -4px;
    margin-left: 5px;
}

.button__icon_auth{
    background-image: url("../icons/ic_auth.svg");
}
/* button END */

/*.form__button{
    width: 184px;
    margin: 44px auto 0;

    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
}

button[data-disabled=true] {
    color: currentColor;
    cursor: not-allowed;
    opacity: .5;
    text-decoration: none;
}*/

.input_error{
    border: 1px solid #FF3B30;
    color: #FF3B30;
}

.modal__channels{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 36px;

    width: 100%;
    height: 104px;

    filter: drop-shadow(0px 12px 36px rgba(54, 77, 136, 0.26));
    border-radius: 8px;
}

.modal__channel{
    display: flex;
    /*flex-direction: column;*/
    align-items: flex-start;
    width: 100%;
    height: 52px;

    background: #FFFFFF;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    padding: 20px 16px 0px 16px;
    cursor: pointer;
}

.modal__channel_icon{
    /* menu_icon */

    width: 32px;
    height: 32px;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    margin-right: 16px;
}

.modal__channel_text{
    width: 233px;
    height: 19px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    color: #373A42;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

