@import url('https://fonts.googleapis.com/css2?family=Onest:wght@400;700&display=swap');

.page {
    background: transparent;
    /*padding: 24px 0;*/

    font-family: 'Onest';
    color: #373A42;
}

.wrap {
    /*max-width: 1092px;*/
    margin: 0 auto;
}

.section {
    background: #E7EDF4;

    max-width: 644px;
    margin: 0px auto;
}

.section__wrap {
    margin: 0 auto;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.success__title {
    font-weight: 900;
    color: #162439;
    height: 62px;
    font-style: normal;
    font-size: 32px;
    line-height: 62px;
    /* identical to box height, or 194% */
    text-align: center;
    letter-spacing: -0.02em;
}

.success__section {
    height: 530px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #E7EDF4;
}

.success__text {
    font-size: 24px;
    font-weight: 400;
    width: 612px;
    height: 64px;
    font-style: normal;
    line-height: 32px;
    /* or 133% */
    text-align: center;
    letter-spacing: -0.02em;
    color: #737C8B;
}

/* button START */
.button {
    display: flex;
    align-items: center;
    justify-content: center;

    border: 0;
    outline: none;

    cursor: pointer;
    transition: 200ms;

    flex-direction: row;
    padding: 16px 40px;
    gap: 10px;

    width: 294px;
    height: 60px;

    background: #162439;
    border-radius: 40px;
}

.button:hover {
    background: linear-gradient(90deg, #366BF4 0%, #45B5F3 100%);
}

.button:active {
    background: linear-gradient(90deg, #3365E2 0%, #40A6E1 100%);
}

.button:disabled {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 40px;
    gap: 10px;

    position: relative;
    width: 294px;
    height: 60px;
    left: 72px;
    top: 831px;

    background: #BBC8D8;
    border-radius: 40px;
}

.button__text {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */
    letter-spacing: -0.02em;
    color: #FFFFFF;
}

/* button END */

.form__button {
    margin: 18px auto 20px;

    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
}

button[data-disabled=true] {
    color: currentColor;
    cursor: not-allowed;
    opacity: .5;
    text-decoration: none;
}

/* input START */
.input {
    align-items: center;
    padding: 18px 16px;
    gap: 10px;

    width: 294px;
    height: 64px;

    color: #162439;
    background: #FFFFFF;
    border-radius: 16px;
    border: 0;

    font-family: 'Onest';
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}

.input_error {
    border-color: #F44C4C;
}

.input_center {
    text-align: center;
}

.input_number {
    width: 55px;
    height: 52px;
    font-weight: 900;
    font-size: 20px;
    line-height: 100%;
    color: #373A42;
    margin: 0 8px;
}


.textarea:hover,
.textarea:focus,
.input:hover,
.input:focus {
    outline: none;
}

.textarea::placeholder,
.input::placeholder {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #737C8B;
}

.textarea {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    line-height: 28px;

    /* identical to box height, or 140% */
    letter-spacing: -0.02em;

    /* Inside auto layout */


    color: #162439;
    backdrop-filter: blur(20px);

    border: 0;
    outline: none;
    resize: none;

    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;

    transition: 200ms;

    /* Frame 733882291 */

    /* Auto layout */
    align-items: flex-start;
    padding: 18px 16px;
    gap: 10px;

    width: 612px;
    height: 122px;

    background: #FFFFFF;
    border-radius: 16px;
}

/* input END */

.form {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    width: 612px;
}

.form__captcha {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    margin: 18px auto 0;
}

.terms {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 5;
    grid-row-end: 5;
    justify-content: center;
    align-items: center;
    text-align: center;

    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    /* or 144% */
    letter-spacing: -0.02em;

    color: #737C8B;
}

.terms > a {
    color: #2F7FFD;
}

.terms_send {
    font-weight: 700;
}